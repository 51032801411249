import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Button,
  HStack,
  Image,
  Spinner,
  theme,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import LandingPage from './components/LandingPage';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true); // State for loading

  return (
    <ChakraProvider theme={theme}>
      <Box
        textAlign="center"
        fontSize="xl"
        bg="white"
        minH="100vh"
        p={3}
      >
        <HStack
          spacing={4}
          padding={4}
          justifyContent="space-between"
          boxShadow="md"
        >
          <Image
            src={'./modernmedchicago.png'}
            alt="Modern Med Chicago"
            width="250px" // Adjust size as needed
            objectFit="contain"
          />

          {/* Centered HStack for images */}
          <HStack spacing={0} mr={12} flexGrow={1} justifyContent="center">
  {/* Link to modernmedconcierge.co */}
  <a href="http://modernmedconcierge.co" target="_blank" rel="noopener noreferrer">
    <Image
      src={'./modernmedconcierge.png'}
      alt="MM Concierge"
      width="250px" // Adjust size as needed
      objectFit="contain"
      cursor="pointer"
    />
  </a>
  
  <Box width="1px" height="30px" bg="gray.300" /> {/* Divider */}
  
  {/* Link to modernmedrecovery.com */}
  <a href="https://modernmedrecovery.com" target="_blank" rel="noopener noreferrer">
    <Image
      src={'./modernmedrecovery.png'}
      alt="MM Recovery"
      width="250px" // Adjust size as needed
      objectFit="contain"
      cursor="pointer"
    />
  </a>
</HStack>


          {/* Contact button styled as plain text */}
          <Button mr={28} variant="link" color="gray.500" onClick={onOpen} size={'lg'}>
            Contact
          </Button>
        </HStack>

        <Drawer isOpen={isOpen} size='md' placement="right" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Contact Us</DrawerHeader>
              <DrawerBody overflowY={'hidden'} overflowX={'hidden'}>
                {isLoading && (
                  <Box textAlign="center" p={4}>
                    <Spinner size="xl" />
                  </Box>
                )}
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfWZiWt86JjQh0fYDQBQcfs0-mH4Rad2UycsPlTjC2vJMEh1w/viewform?embedded=true"
                  width="100%"
                  height="800"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  onLoad={() => setIsLoading(false)} // Set loading to false on load
                >
                  Loading…
                </iframe>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>

        {/* Centering the main image */}
        <VStack justify='center' align='center' p={8}>
          <Box maxWidth="600px" width="100%">
            <Image src={'./homepage2.png'} width="100%" objectFit="contain" />
          </Box>
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;
